const MAX_WIDTH = 600
const MAX_HEIGHT = 600

export function toBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = function (e) {
      const img = document.createElement("img")
      img.src = e.target?.result as string

      img.onload = function () {
        const canvas = document.createElement("canvas")
        let ctx = canvas.getContext("2d")
        ctx?.drawImage(img, 0, 0)

        let width = img.width
        let height = img.height

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
        }
        canvas.width = width
        canvas.height = height
        ctx = canvas.getContext("2d")
        ctx?.drawImage(img, 0, 0, width, height)

        const dataurl = canvas.toDataURL(file.type)
        resolve(dataurl as string)
      }
    }
    reader.onerror = (error) => reject(error)
  })
}
