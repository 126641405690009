import React, { useCallback } from "react"
import Layout from "../../components/layout/Layout"
import { Helmet } from "react-helmet"
import { useRequireAuth } from "../../hooks/useRequireAuth"
import { NoSsr } from "../../components/NoSsr"
import { AtkForm } from "../../components/atk/AtkForm"
import { useAddAtkResultOneMutation } from "../../generated/graphql"
import { AtkFormData } from "../../components/atk/types"
import { navigate } from "gatsby"
import toast from "react-hot-toast"

const AtkAdd = () => {
  useRequireAuth()

  const [fn, { loading }] = useAddAtkResultOneMutation({
    onCompleted: () => {
      navigate("/atk")

      toast.success("บันทึกผลตรวจ ATK สำเร็จ!", { position: "top-right" })
    },
  })

  const handleSubmit = useCallback(
    (formData: AtkFormData) => {
      fn({
        variables: {
          object: {
            brand_id: formData.brand_id,
            date: formData.date,
            image: formData.image,
            infected: formData.infected,
            time: formData.time,
          },
        },
      })
    },
    [fn]
  )

  return (
    <Layout>
      <Helmet>
        <title>ผลตรวจ ATK - GoFight | KMITL</title>
      </Helmet>
      <NoSsr>
        <AtkForm onSubmit={handleSubmit} loading={loading} />
      </NoSsr>
    </Layout>
  )
}

export default AtkAdd
