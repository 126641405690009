import React, { ReactNode, FC } from "react"
import { useEnhancedEffect } from "../hooks/useEnhancedEffect"

export interface NoSsrProps {
  /**
   * If `true`, the component will not only prevent server-side rendering.
   * It will also defer the rendering of the children into a different screen frame.
   * @default false
   */
  defer?: boolean

  /**
   * The fallback content to display.
   * @default null
   */
  fallback?: ReactNode
}

export const NoSsr: FC<NoSsrProps> = (props) => {
  const { children, defer = false, fallback = null } = props
  const [mountedState, setMountedState] = React.useState(false)

  useEnhancedEffect(() => {
    if (!defer) {
      setMountedState(true)
    }
  }, [defer])

  React.useEffect(() => {
    if (defer) {
      setMountedState(true)
    }
  }, [defer])

  return <React.Fragment>{mountedState ? children : fallback}</React.Fragment>
}
