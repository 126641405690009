import React, {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
  VFC,
} from "react"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid"
import clsx from "clsx"

interface DataRecord extends Record<string, unknown> {
  id: string
}

export interface SelectInputProps<T = DataRecord> {
  id?: string
  name: string
  label?: string
  options: T[]
  defaultValue?: string
  value?: string
  emptyText?: string
  optionText?: (option: T) => string
  onChange?: (id?: string) => void
  error?: boolean
  disabled?: boolean
  required?: boolean
}

function defaultOptionText(option: DataRecord) {
  return option.id as ReactNode
}

export const SelectInput: VFC<SelectInputProps> = (props) => {
  const {
    defaultValue,
    options,
    optionText = defaultOptionText,
    emptyText,
    value,
    label,
    disabled,
    error,
    required,
    onChange,
  } = props
  const [selectedId, setSelectedId] = useState(value || defaultValue)

  useEffect(() => {
    setSelectedId(value)
  }, [value])

  const handleChange = useCallback(
    (option: DataRecord) => {
      if (option) {
        setSelectedId(option.id)
        onChange?.(option.id)
      } else {
        setSelectedId(undefined)
        onChange?.(undefined)
      }
    },
    [onChange]
  )

  const selected = useMemo(
    () => options.find((option) => option.id === selectedId),
    [options, selectedId]
  )

  return (
    <Listbox disabled={disabled} value={selected} onChange={handleChange}>
      {({ open }) => (
        <>
          <Listbox.Label
            className={clsx(`block text-sm font-medium text-gray-700`, {
              "text-red-700": error,
            })}
          >
            {label}
            {required ? (
              <span className="text-semibold text-red-700">*</span>
            ) : null}
          </Listbox.Label>
          <div className="mt-1 relative">
            <Listbox.Button
              className={clsx(
                `bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`,
                { "border-red-700": error, "border-2": error }
              )}
            >
              <span className="block truncate">
                {selected ? optionText(selected) : emptyText}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      clsx(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "cursor-default select-none relative py-2 pl-3 pr-9"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={clsx(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {optionText(option)}
                        </span>

                        {selected ? (
                          <span
                            className={clsx(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
