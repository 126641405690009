import React, { Fragment } from "react"
import { Control, Controller } from "react-hook-form"
import { useGetAtkBrandsQuery } from "../../generated/graphql"
import HelperText from "../form/HelperText"
import { SelectInput } from "../form/SelectInput"

export const AtkBrandInput = ({ control }: { control: Control }) => {
  const { data, loading } = useGetAtkBrandsQuery({
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-only",
  })

  const options =
    data?.returning.map((row) => ({
      id: row.id,
      name: row.name,
    })) || []

  return (
    <Controller
      control={control}
      name="brand_id"
      render={({
        field: { name, onChange, value },
        fieldState: { invalid, error },
      }) => (
        <Fragment>
          <SelectInput
            id="brand_id"
            label="ATK Brand"
            emptyText="กรุณาเลือก ATK Brand"
            disabled={loading}
            options={options}
            optionText={(option) => option.name as string}
            value={value}
            name={name}
            onChange={(id) => onChange(id)}
            required
            error={invalid}
          />
          {error && <HelperText color="red-700">{error?.message}</HelperText>}
        </Fragment>
      )}
    />
  )
}
