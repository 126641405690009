import { useSession } from "./useSession"
import { navigate } from "gatsby"
import { isBrowser } from "../libs"

/**
 * @description
 * React Hooks สำหรับยืนยันสิทธิ์ว่าผู้ใช้ Logged in เข้าระบบแล้ว
 * ถ้าหากไม่ login จะทำการ redirect ไปยังหน้า login
 *
 * @example
 * const ProfilePage = () => {
 *    useRequireAuth();
 *    return <div>User Profile</div>
 *  }
 * @returns
 */
export function useRequireAuth() {
  const { isAuthenticated, loading } = useSession()

  if (!loading && !isAuthenticated && isBrowser()) {
    navigate("/login")
  }

  return undefined
}
