import { addDays, format } from "date-fns"
import * as yup from "yup"

export function getSchema() {
  const maxDate = format(addDays(new Date(), 1), "yyyy-MM-dd")
  const schema = yup
    .object()
    .shape({
      brand_id: yup.string().required("กรุณาระบุ ATK Brand"),
      infected: yup.string().required("กรุณาระบุผลตรวจ").nullable(),
      date: yup
        .date()
        .nullable()
        .transform((v) =>
          v instanceof Date && !isNaN(v as unknown as number) ? v : null
        )
        .max(maxDate, "กรุณาระบุวันที่ให้ถูกต้อง (ไม่เกินวันปัจจุบัน)")
        .required("กรุณาระบุวันที่ตรวจ"),
      time: yup.string().required("กรุณาระบุเวลาตรวจ"),
      image: yup.string().required("กรุณาอัปโหลดรูปผลตรวจ ATK"),
    })
    .required()
  return schema
}
