import clsx from "clsx"
import React from "react"

export interface RadioInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string
  label?: string
  id?: string
  placeholder?: string
  error?: boolean
}

export const RadioInput = React.forwardRef((props: RadioInputProps, ref) => {
  const { id, label, error, required, ...restProps } = props
  return (
    <div className="text-md ">
      <label
        className={clsx(`flex items-center font-medium text-gray-700`, {
          "text-red-700": error,
        })}
      >
        <input
          type="radio"
          className="focus:ring-orange-500 h-5 w-5 text-orange-600 border-gray-300"
          required={required}
          ref={ref as React.LegacyRef<HTMLInputElement>}
          {...restProps}
        />
        <span className="ml-1.5">{label}</span>
        {required ? (
          <span className="text-semibold text-red-700">*</span>
        ) : null}
      </label>
    </div>
  )
})
