import React, { Fragment, memo } from "react"
import { Control, Controller, useForm, useWatch } from "react-hook-form"

import { yupResolver } from "@hookform/resolvers/yup"
import { Loading } from "../ui/Loading"
import HelperText from "../form/HelperText"
import Input from "../form/Input"
import { ErrorMessage } from "@hookform/error-message"
import { toBase64 } from "../../utils"
import { format } from "date-fns"
import { RadioInput } from "../form/RadioInput"
import clsx from "clsx"
import { getSchema } from "./schemaValidation"
import { AtkBrandInput } from "./AtkBrandInput"
import { AtkFormData } from "./types"

export interface AtkFormProps {
  onSubmit: (formData: AtkFormData) => void
  loading?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function transform(formData: Record<string, any>): AtkFormData {
  return {
    brand_id: formData.brand_id,
    image: formData.image,
    time: formData.time,
    date: format(formData.date, "yyyy-MM-dd"),
    infected: formData.infected === "true",
  }
}

export const AtkForm = ({ onSubmit, loading }: AtkFormProps) => {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getSchema()),
  })

  const maxDate = format(new Date(), "yyyy-MM-dd")

  return (
    <form
      className="md:space-y-4 space-y-6 bg-white rounded-lg shadow divide-gray-200 p-6"
      onSubmit={handleSubmit((formData) => onSubmit(transform(formData)))}
      noValidate
    >
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        บันทึกผลตรวจ ATK
      </h3>
      <div>
        <AtkBrandInput control={control} />
      </div>
      <div>
        <Input
          id="date"
          label="วันที่ตรวจ"
          type="date"
          max={maxDate}
          required
          error={!!errors["date"]}
          {...register("date", { valueAsDate: true })}
        />
        <ErrorMessage
          errors={errors}
          name="date"
          render={({ message }) => (
            <HelperText color="red-700">{message}</HelperText>
          )}
        />
      </div>
      <div>
        <Input
          id="time"
          label="เวลาตรวจ"
          type="time"
          required
          error={!!errors["time"]}
          {...register("time")}
        />
        <ErrorMessage
          errors={errors}
          name="time"
          render={({ message }) => (
            <HelperText color="red-700">{message}</HelperText>
          )}
        />
      </div>
      <div>
        <Controller
          control={control}
          name="image"
          render={({
            field: { name, onChange },
            fieldState: { error, invalid },
          }) => (
            <Fragment>
              <Input
                id="image"
                label="รูปผลตรวจ ATK"
                type="file"
                name={name}
                required
                accept="image/png, image/gif, image/jpeg"
                error={invalid}
                onChange={async (e) => {
                  const file = e.target.files?.[0]
                  if (file) {
                    const content = await toBase64(file)
                    onChange(content as string)
                  }
                }}
              />
              <HelperText className="text-medium">
                รองรับเฉพาะรูปภาพ .png, gif, jpg และ jpeg
              </HelperText>
              {error && (
                <HelperText color="red-700">{error?.message}</HelperText>
              )}
            </Fragment>
          )}
        />
      </div>
      <div>
        <PreviewImage control={control} />
      </div>
      <div className="space-y-3">
        <label
          className={clsx(`flex items-center font-medium text-gray-700`, {
            "text-red-700": errors["infected"],
          })}
        >
          ผลตรวจ ATK <span className="text-semibold text-red-700">*</span>
        </label>
        <RadioInput
          label="พบเชื้อ"
          id="true"
          value="true"
          {...register("infected")}
        />
        <RadioInput
          label="ไม่พบเชื้อ"
          id="false"
          value="false"
          {...register("infected")}
        />
        <ErrorMessage
          errors={errors}
          name="infected"
          render={({ message }) => (
            <HelperText color="red-700">{message}</HelperText>
          )}
        />
      </div>
      {/* <div className="space-y-3">
        <label
          className={clsx(`flex items-center font-medium text-gray-700`, {
            "text-red-700": errors["infected"],
          })}
        >
          การตรวจ (ตรวจเอง/สุ่มตรวจ){" "}
          <span className="text-semibold text-red-700">*</span>
        </label>
        <RadioInput
          label="พบเชื้อ"
          id="true"
          value="true"
          {...register("infected")}
        />
        <RadioInput
          label="ไม่พบเชื้อ"
          id="false"
          value="false"
          {...register("infected")}
        />
        <ErrorMessage
          errors={errors}
          name="infected"
          render={({ message }) => (
            <HelperText color="red-700">{message}</HelperText>
          )}
        />
      </div> */}
      <div className="pt-12">
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium bg-orange-600 hover:bg-orange-700 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          disabled={loading}
        >
          {loading && <Loading />}
          บันทึกข้อมูล
        </button>
      </div>
    </form>
  )
}

const PreviewImage = memo(({ control }: { control: Control }) => {
  const base64 = useWatch({
    control,
    name: "image",
  })

  if (!base64) return <Fragment />

  return (
    <img
      src={base64 as unknown as string}
      alt="รูปผลตรวจATK"
      className="max-h-56"
      id="preview"
    />
  )
})
